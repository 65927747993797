<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Instant Bank Payment</h3>
            </div>

            <div class="card-body min-vh">
              <div class="mb-2">
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  @click="craeteInstant"
                  >Create new Payment</el-button
                >
              </div>

              <el-table
                v-loading="loading"
                :data="tableData"
                border
                style="width: 100%"
              >

                <el-table-column
                  header-align="center"
                  align="center"
                  prop="name"
                  label="Name"
                  min-width="180"
                >
                </el-table-column>
                <el-table-column
                  header-align="center"
                  align="center"
                  max-width="100"
                  prop="currencyCode"
                  label="Currency"
                >
                </el-table-column>
                <el-table-column
                  header-align="center"
                  align="center"
                  max-width="180"
                  prop="payByType"
                  label="Pay by"
                >
                </el-table-column>
                <el-table-column
                  header-align="center"
                  align="center"
                  min-width="180"
                  prop="payeeInfo"
                  label="Payee Info"
                >
                </el-table-column>
          
                <el-table-column
                  header-align="center"
                  align="center"
                  fixed="right"
                  prop="uid"
                  label="Actions"
                  width="150"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      class="btn-mini"
                      @click="paymentInstantPayee(scope.row)"
                      >Payment</el-button
                    >
                    <el-button
                      type="danger"
                      class="btn-mini"
                      @click="deletePayee(scope.row)"
                      >Delete</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="card-footer">
              <div class="row justify-content-xl-center">
                <div class="col-xl-12 text-center"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <Shortcut />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Payee from "@/api/payee";

import Shortcut from "@/components/MenuShortcut.vue";
export default {
  components: {
    Shortcut,
  },

  data() {
    return {
      loading: false,
      tableData: [],
    };
  },

  methods: {
    craeteInstant() {
      this.$router.push("/instant-bank-payment/create");
    },
    getPayeeInstant() {
      this.loading = true;
      Payee.getPayeeInstantList().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.tableData = data;
        }
      });
    },
    paymentInstantPayee(item) {
      this.$router.push(`/instant-bank-payment/payment/${item.uid}`);
    },
    deletePayee(item) {
      this.$confirm(
        "Are you sure you want to delete this item. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          Payee.delPayeeInstant(item.uid).then(({ result, message }) => {
            if (result) {
              this.$swal(`Message`, `Delete is success`, "success").then(
                () => {
                  this.getPayeeInstant();
                }
              );
            } else {
              this.$swal("Message", message, "error");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
  },
  created() {
    this.getPayeeInstant();
  },
};
</script>
<style></style>
